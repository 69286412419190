import React from 'react';
import Layout from '../../components/Dashboard/Layout';

const Dashboard: React.FC = () => (
  <Layout>
    <h1>Dashboard</h1>
  </Layout>
);

export default Dashboard;
